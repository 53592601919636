import React from "react"
import Layout from "../../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import PartnerCarousel from "../../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../../components/SectionTitle"
import Services from "../../../components/Services"
import { Banner } from "../../../components/Banner"
import BusinessOffers from "../../../components/business/BusinessOffers"
import SEO from "../../../components/seo"

export default function BespokeOfficeFurniture() {
  const data = useStaticQuery(graphql`
    query businessBespokeOfficeFurniture {
      CCTVSystems: file(
        relativePath: { eq: "services/AdobeStock_155988837.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      DecorationWorks: file(
        relativePath: { eq: "services/AdobeStock_119025976.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      SuspendedCeilings: file(
        relativePath: { eq: "services/AdobeStock_178325782.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      AccessControlSystems: file(
        relativePath: { eq: "services/AdobeStock_158155353.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      GeneralCarpentryWorks: file(
        relativePath: { eq: "services/AdobeStock_233975638.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      AirConditioningSystems: file(
        relativePath: { eq: "services/AdobeStock_218238116.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      FireAlarmSystem: file(
        relativePath: { eq: "services/AdobeStock_296112197.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      HeatingVentilation: file(
        relativePath: { eq: "services/AdobeStock_278869657.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      PartitioningSystem: file(
        relativePath: { eq: "services/AdobeStock_251190440.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ElectricalWork: file(
        relativePath: { eq: "services/AdobeStock_170179753.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "CCTV Systems",
      image: data.CCTVSystems.childImageSharp.fixed,
    },
    {
      title: "Decoration Works",
      image: data.DecorationWorks.childImageSharp.fixed,
    },
    {
      title: "Suspended Ceilings",
      image: data.SuspendedCeilings.childImageSharp.fixed,
    },
    {
      title: "Access Control Systems",
      image: data.AccessControlSystems.childImageSharp.fixed,
    },
    {
      title: "General Carpentry Works",
      image: data.GeneralCarpentryWorks.childImageSharp.fixed,
    },
    {
      title: "Air Conditioning Systems",
      image: data.AirConditioningSystems.childImageSharp.fixed,
    },
    {
      title: "Fire & Intruder Alarm Systems",
      image: data.FireAlarmSystem.childImageSharp.fixed,
    },
    {
      title: "Heating Plumbing & Ventilation",
      image: data.HeatingVentilation.childImageSharp.fixed,
    },
    {
      title: "Partitioning Systems – Fixed & De-mountable",
      image: data.PartitioningSystem.childImageSharp.fixed,
    },
    {
      title: "All Cabling & Electrical Works",
      image: data.ElectricalWork.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Bespoke Office Furniture Business" />
      <BusinessOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Bespoke Office Furniture Solutions"
              description={
                <>
                  <p>
                    In addition to straight forward furniture requirements,
                    Compulease™ can redesign or refurbish an office or a whole
                    building to your exact requirements.
                  </p>
                  <p>
                    This service includes the production of bespoke furniture so
                    you no longer need to worry about the strange shape of an
                    office or the nooks and crannies, as our design team will
                    help you to maximise the full space potential of the room.
                  </p>
                </>
              }
            />
          </Col>
        </Row>
        <PeaceOfMindGuarantee />
        <Services title="Solutions we provide" services={services} />
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="Speak to our friendly customer service team for all your business computer leasing and finance needs"
          link={{
            text: "Get in touch",
            to: "/business/ContactUs/",
          }}
        />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}
